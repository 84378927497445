
@import "../../utilities/colors";
@import "../../utilities/variables";
@import "../../utilities/functions";

.product-tile-ford {
    width: 100%;
    height: auto;
    background-color: $white;
    -webkit-box-shadow: 0px 0px 20px -8px rgba(145,145,145,0.40);
    -moz-box-shadow: 0px 0px 20px -8px rgba(145,145,145,0.40);
    box-shadow: 0px 0px 20px -8px rgba(145,145,145,0.40);
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
        -webkit-box-shadow: 0px 0px 20px -8px rgba(145,145,145,1);
        -moz-box-shadow: 0px 0px 20px -8px rgba(145,145,145,1);
        box-shadow: 0px 0px 20px -8px rgba(145,145,145,1);
        transition: all 0.5s ease-in-out;
        background-color: $ford-blue;

        .product-tile-ford__content {
            h1 {
                color: $white;
            }
    
            h3 {
                color: $white;
            }
        }

        .product-tile-ford__content__price {
            color: $white;
        }
    }

    &__image {
        img {
            width: 100%;
            height: rem-calc(250px);
            object-fit: cover;
        }
    }

    &__content {
        width: 100%;
        min-height: rem-calc(165px);
        padding: rem-calc(13px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            min-height: rem-calc(120px);
        }

        h1 {
            color: $ford-blue;
            font-size: rem-calc(18px);
            font-weight: $font-weight-bold;
        }

        h3 {
            color: $ford-skyview;
            font-size: rem-calc(12px);
            font-weight: $font-weight-medium;
        }

        &__price {
            color: $ford-blue;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem-calc(12px);

            .product-price {
                span {
                    display: flex;
                    flex-direction: column;
                }

                del {
                    text-decoration-color: $ford-skyview;
                    font-size: rem-calc(12px);
                }

                .strike-through {
                    color: $ford-skyview;
                }

                .sales {
                    font-size: rem-calc(20px);
                    font-weight: $font-weight-medium;
                }
            }
        }
    }

}
