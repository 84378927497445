@import "../utilities/colors";
@import "../utilities/functions";
@import "../utilities/variables";
@import "../utilities/mixins";

.btn {
    padding: rem-calc(10px) rem-calc(25px);
    border-radius: rem-calc(20px);
    font-weight: $font-weight-regular;
    font-size: rem-calc(17px);
    border-color: none;
    transition: all 0.5s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
}

.btn-primary {
    background-color: $ford-blue;
    border-color: transparent;
    color: $light-gray;
}

.btn-primary:hover {
    border-color: $white;
    border-color: transparent;
    background-color: $ford-skyview;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $off-black;
    border-color: $ford-blue;
    opacity: 0.5;
}

.btn-outlined {
    background-color: unset;
    border-color: #00095b;
    color: #00095b !important;

    &:hover {
        background-color: rgba(0, 9, 91, 0.05);
    }
}

.btn-arrow-right {
    @include button-arrow;
}

.btn-arrow-left {
    @include button-arrow;
    transform: rotate(180deg);
}

.add-to-wish-list {
    background-color: $light-gray;
    color: $ford-blue;
    border-color: $ford-blue;
    opacity: 0.7;
    width: rem-calc(228.19px) !important;

    @include media-breakpoint-down(xs) {
        width: 100% !important;
    }

    &:hover {
        border-color: transparent;
        opacity: 0.7;
        background-color: $ford-grabber;
    }

    &:disabled {
        background-color: $light-gray;
        color: $off-black;
        opacity: 0.3;
        border-color: $off-black;
    }
}
