@use "sass:map";
@use "functions" as *;

// Font-Family
$font-family: "Ford F-1";

// Grid
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 1080px,
    xl: 1440px
);

$screen-xs: map.get($grid-breakpoints, xs);
$screen-sm: map.get($grid-breakpoints, sm);
$screen-md: map.get($grid-breakpoints, md);
$screen-lg: map.get($grid-breakpoints, lg);
$screen-xl: map.get($grid-breakpoints, xl);

$container-max-width: 1400px;

$container-max-widths: (
    md: 100%,
    lg: 1280px,
    xl: 1400px
) !default;


// Font Sizes
$font-size-xxlg: rem-calc(76px);
$font-size-xlg: rem-calc(32px);
$font-size-lg: rem-calc(22px);
$font-size-md: rem-calc(16px);
$font-size-sm: rem-calc(10px);

// Font Weights

$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-regular: 500;
$font-weight-semi-light: 400;
$font-weight-light: 300;

// Content Spacing
$content-block-spacing: rem-calc(96px);

// Animations
$animation-duration-time: 0.3s;

//Bootstrap Overrides
$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
