
@import "../../utilities/colors";
@import "../../utilities/functions";

.image-component {
    transition: all 0.5s ease-in-out;

    &:hover {
        opacity: 0.9;
    }

    img {
        max-height: rem-calc(320px);
    }

    .image-heading-container {
        top: rem-calc(30px);
        padding-left: rem-calc(30px);

        h1,
        h2,
        h3,
        h4,
        p {
            color: $white;
        }
    }
}