@import "colors";

@mixin button-arrow(
    $backgroundColor: $ford-blue,
    $color: $white,
    $hoverBackgroundColor: $ford-twilight,
    $disabledBackgroundColor: $light-gray
) {
    height: rem-calc(42px);
    width: rem-calc(42px);
    padding: 0;
    border-radius: 100%;
    background-color: $backgroundColor;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 100%;
        background-color: $color;
        -webkit-mask-image: url("../icons/arrow-right.svg");
        mask-image: url("../icons/arrow-right.svg");
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &:not(:disabled):not(.disabled):not(.swiper-button-disabled) {
        @media (min-width: $screen-sm) {
            &:hover,
            &:focus,
            &:active {
                background-color: $hoverBackgroundColor;
            }
        }
    }

    &:disabled,
    &.disabled,
    &.swiper-button-disabled {
        background-color: $disabledBackgroundColor;
        pointer-events: none;
        transition: all 0.4s ease-in-out;
    }
}