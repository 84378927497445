
// Ford Blue Colors
$ford-blue: #00095B;
$ford-twilight: #00142E;
$ford-skyview: #066FEF;
$ford-grabber: #1700F4;

// Main Colors
$white: #FFF;
$light-gray: #F0F0F0;
$off-black: #0F0F0F;
$black: #000;

// Text Color Utility Classes
.text-ford-blue {
    color: $ford-blue;
}

.text-ford-twilight {
    color: $ford-twilight;
}

.text-ford-skyview {
    color: $ford-skyview;
}

.text-ford-grabber {
    color: $ford-grabber;
}

.text-white {
    color: $white;
}

.text-light-gray {
    color: $light-gray;
}

.text-off-black {
    color: $off-black;
}

.text-black {
    color: $black;
}

// Background Color Utility Classes
.bg-ford-blue {
    background-color: $ford-blue;
}

.bg-ford-twilight {
    background-color: $ford-twilight;
}

.bg-ford-skyview {
    background-color: $ford-skyview;
}

.bg-ford-grabber {
    background-color: $ford-grabber;
}

.bg-white {
    background-color: $white;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-off-black {
    background-color: $off-black;
}

.bg-black {
    background-color: $black;
}
