@import "utilities/colors";
@import "utilities/variables";
@import "utilities/functions";

.search-results {
    .tab-pane {
        color: $ford-blue;
    }
  
    .btn-outline-primary {
        border-color: $ford-skyview; 
        color: $ford-skyview;

        &:hover {
            background-color: $ford-grabber;
            color: $white;
        }
    }

    .refinements {
    .card {
        border-color: $ford-skyview;
        color: $ford-skyview;

        .card-header {
            border-color: $ford-skyview;

            h2 {
                color: $ford-skyview;
            }
        }
    }

    button {
        span {
            color: $ford-skyview;
        }
    }
    } 

  #refinement-categoria {
    button {
        color: $ford-skyview;
    }
  }

  .brand {
    text-align: left;
  }

  .btn {
    &:hover {
        transform: none;
        }
    }
}

.result-count {
    .search-keywords {
        color: $ford-skyview;
    }
}

.nav-tabs {
    .nav-link.active {
        border-bottom: 0.188em solid $ford-skyview;
    }
}

#product-tab {
    color: $ford-blue;
}

.hero {
    position: static;
    height: rem-calc(200px);
    background-color: $ford-blue;

    h1 {
        background-color: transparent;

        &.page-title::before {
            background-color: transparent;
        }
    }
}

.product-grid {
    gap: rem-calc(40px) 0;
    margin-bottom: rem-calc(25px);
}
