@import "../utilities/functions";
@import "../utilities/colors";
@import "../utilities/variables";

footer {
    background: $light-gray;

    h2,
    .title {
        font-family: $font-family !important;
        color: $ford-blue !important;
    }

    a {
        color: $off-black;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(14px);
            font-weight: $font-weight-semi-light;
        }
    }

    .content {
        li {
            height: auto;
            margin-bottom: rem-calc(10px);
        }
    }
}

.email-signup-message,
.email-signup-alert {
    z-index: 20;
}

.footer-item {
    @media (min-width: $screen-sm) and (max-width: rem-calc(1155px)) {
        ul {
            li {
                margin-bottom:  1rem !important;
                a {
                    display: flex;
                    flex: 1;
                    font-size: rem-calc(12px) !important;
                }
            }
        }
    }
}
