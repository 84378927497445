@import "../../utilities/colors";
@import "../../utilities/variables";
@import "../../utilities/functions";

.accordian-pdesign {
  margin-bottom: 2rem;
  color: $ford-blue;

  button {
    color: $ford-skyview;
  }
}
