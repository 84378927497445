@import "../utilities/colors";
@import "../utilities/variables";
@import "../utilities/functions";

#maincontent {
    .product-detail {
        .product-breadcrumb {
            .breadcrumb {
                .breadcrumb-item {
                    a {
                        color: $ford-blue;

                        &:hover {
                            color: $ford-grabber
                        }
                    }
                }
            }
        }

        .carousel-item {
            background-color: $white;

            img {
                width: 100%;
                height: 500px;
                object-fit: contain;

                @include media-breakpoint-down(md) {
                    height: rem-calc(300px);
                }
            }
        }

        .product-name {
            font-size: $font-size-lg;
            font-weight: $font-weight-regular;

            @include media-breakpoint-up(md) {
                font-weight: $font-weight-medium;
                font-size: $font-size-xlg;
            }
        }

        .product-number-rating {
            border: 0;
            background: $light-gray;
            padding: 0 rem-calc(15px);

            .product-number {
                color: $ford-blue;
                font-size: rem-calc(16px);
            }
        }

        .attributes {
            padding: 0;
        }

        .prices-add-to-cart-actions {
            padding: 0;
        }

        .price {
            text-align: left;

            @include media-breakpoint-down(md) {
                padding-left: rem-calc(15px);
            }
        }

        .cart-and-ipay {
            text-align: left;
        }

        .cart-and-ipay {
            margin-top: rem-calc(8px);
        }

        .callout {
            font-size: $font-size-md;
            color: $red;
        }
    }
}

.description-and-detail {

    p,
    span,
    li {
        color: $off-black;

        &::marker {
            color: $ford-blue;

        }
    }
}
