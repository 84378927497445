@import "../utilities/colors";
@import "../utilities/functions";
@import "../utilities/variables";

.custom-select {
    border-color: $light-gray;
    color: $ford-skyview;
    border-radius: rem-calc(4px);
    height: auto;
    padding: rem-calc(10px) rem-calc(20px);
}
