@import "../utilities/colors";
@import "../utilities/variables";
@import "../utilities/functions";

.wish-list-wrapper {
  width: 100% !important;
}

.add-to-cart-wishlist {
  button {
    margin-bottom: rem-calc(20px);
  }
}

.login-banner {
  background-image: none !important; 
}

.quantity-form-wishlist {
  max-width: rem-calc(80px);
  margin-bottom: rem-calc(20px);
}

.wishlistItemCards {
  .card.product-info {
    padding: rem-calc(8px) rem-calc(16px);
  }
}
