@import "../utilities/functions";
@import "../utilities/variables";
@import "../utilities/typography";
@import "menu";

.ford-brand {
    display: flex;
    align-items: center;

    img {
        width: rem-calc(130px);
        height: auto;
    }

    @include media-breakpoint-up(md) {
        width: rem-calc(130px);
        height: auto;
    }
}

.site-search {
    width: 100%;

    button.fa-search,
    button.fa-times {
        color: $ford-blue;
        top: 50%;
        transform: translateY(-50%);
    }

    form[role="search"] {
        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }
}

.suggestions-wrapper {
    .suggestions {
        position: absolute;
        height: fit-content;
        width: 100%;
        // Manually setup the top position for the suggestions container because the current script in base doesn't work for the sticky header in mobile.
        top: 0 !important;
    }
}

.search-mobile {
    .site-search {
        width: 100%;
    }
}

.search {
    width: auto;
    flex: 1;

    input {
        height: 42px;
    }
}

.navbar > .close-menu {
    background-color:$ford-skyview;

    button {
        color: $white;
    }
}

.main-menu {
    background-color: #f9f9f9;

    .menu-group {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            justify-content: start;
        }

        .nav-link {
            color: $ford-blue;
            font-size: 14px;
            border-bottom: 2px solid $white;
            transition: all 0.2s ease-in-out;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                margin-bottom: 8px;
            }

            &:hover {
                color: $ford-skyview;
                border-bottom: 2px solid $ford-skyview;
            }
        }

        @include media-breakpoint-down(sm) {
            .nav.navbar-nav {
                width: 100%;
            }
        }
    }
}

.header-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $off-black;
    text-align: center;
    color: $white;
}

.page-title {
    display: flex;
    align-items: center;
    height: rem-calc(200px);
    background-color: $ford-blue;

    h1 {
        font-size: rem-calc(48px);
        color: $white;
    }
}
