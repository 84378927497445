@import "../../utilities/variables";

.pd-grid-layout {
    &__wrapper {
        display: grid;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr !important;
        }
    }
}
