@import "./functions";
@import "./variables";
@import "./colors";

/**
* Defines the font faces for the 'Ford F-1' font family with different weights and styles.
**/

@font-face {
    font-family: 'Ford F-1';
    src: url('../../fonts/FordF1-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../../fonts/FordF1-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../../fonts/FordF1-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../../fonts/FordF1-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../../fonts/FordF1-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Font Weights utility classes

.font-weight-bold {
    font-weight: $font-weight-bold;
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-regular {
    font-weight: $font-weight-regular;
}

.font-weight-semi-light {
    font-weight: $font-weight-semi-light;
}

.font-weight-light {
    font-weight: $font-weight-light;
}

// Font sizes utility classes

.font-size-extra-large {
    font-size: $font-size-xxlg;
}

.font-size-large {
    font-size: $font-size-xlg;
}

.font-size-medium {
    font-size: $font-size-lg;
}

.font-size-regular {
    font-size: $font-size-md;
}

.font-size-small {
    font-size: $font-size-sm;
}

// Overwrites basic html markdown elements font-family

* {
    font-family: $font-family;
}

body {
    font-family: $font-family;
}

html {
    font-family: $font-family;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: $font-family;
}

h1 {
    font-size: $font-size-xxlg;
    font-weight: $font-weight-medium;
    color: $ford-blue;
}

h2 {
    font-size: $font-size-xlg;
    font-weight: $font-weight-medium;
    color: $ford-blue;
}

h3 {
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
    color: $ford-blue;
}

p {
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
    color: $ford-blue;
}

small {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    color: $ford-blue;
}

a {
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
    color: $ford-blue;
    transition: all $animation-duration-time ease-in-out;

    &:hover {
        text-decoration: none;
    }
}
