@import "../../utilities/colors";
@import "../../utilities/variables";
@import "../../utilities/functions";

.pd-reset-form {
  display: grid;
  grid-template-columns: 1fr 38%;
  gap: rem-calc(125px);

  @media (max-width: $screen-lg) {
      grid-template-columns: 1fr 1fr;
      gap: rem-calc(30px);
  }

  @media (max-width: $screen-md) {
      grid-template-columns: 1fr;
      gap: rem-calc(30px);
  }

  label {
    color: $ford-twilight;
    font-weight: 400;
  }
}