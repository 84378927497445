@import "../../utilities/colors";
@import "../../utilities/variables";
@import "../../utilities/functions";

.banner {
    background-color: black;

    picture {
        background-color: black;

        img {
            max-height: rem-calc(450px);
            object-fit: cover;
            object-position: var(--focal-point-x-desktop) var(--focal-point-y-desktop);

            @include media-breakpoint-down(md) {
                height: rem-calc(450px);
                opacity: 0.6;
                object-position: var(--focal-point-x-tablet) var(--focal-point-y-tablet);
            }

            @include media-breakpoint-down(sm) {
                object-position: var(--focal-point-x-mobile) var(--focal-point-y-mobile);
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: rem-calc(50px);

        @include media-breakpoint-down(md) {
            left: 0;
            padding: 0 rem-calc(40px);
        }

        .content-wrapper {
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding: rem-calc(16px) 0 rem-calc(40px)
            }
        }

        h1 {
            color: $white;
            font-weight: 300;
            font-size: rem-calc(54px);
            max-width: rem-calc(700px);
            line-height: rem-calc(65px);

            @include media-breakpoint-down(md) {
                font-size: rem-calc(38px);
                line-height: rem-calc(40px);
            }
        }

        p {
            color: $white;
            max-width: rem-calc(600px);
            margin-top: rem-calc(15px);
        }

        button {
            margin-top: rem-calc(15px);
            color: $ford-blue;
            background-color: $light-gray;
        }
    }
}
