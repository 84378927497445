@import "swiper/swiper-bundle.min";
@import "../utilities/functions";

.swiper-carousel {
    position: relative;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    width: auto;
    height: auto;
    padding: rem-calc(10px);
}

.swiper-button-next {
    right: 0;
    color: $ford-blue;
}

.swiper-button-prev {
    left: 0;
    color: $ford-blue;
}

.swiper-navigation {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    padding: 0 rem-calc(16px);
}

.swiper-horizontal {
    > .swiper-pagination-bullets {
        bottom: var(--swiper-pagination-bottom, rem-calc(16px));
    }
}

.swiper-pagination-bullet {
    background-color: $light-gray;
    opacity: 1;
    width: rem-calc(15px);
    height: rem-calc(15px);
    border: 2px solid $ford-blue;

    &.swiper-pagination-bullet-active {
        background-color: $ford-blue;
    }
}
