@import "../utilities/colors";
@import "../utilities/variables";
@import "../utilities/functions";

#checkout-main {
  button.submit-customer {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .order-product-summary {
    color: $ford-blue !important;
  }

  .checkout-title-area {
    background-color: $ford-blue;

    h1 {
      color: $white;
    }
  }

  .card-header {
    background-color: $ford-blue;

    h2 {
      color: $white;
    }
  }

  .submit-shipping {
    margin-top: 1rem;
  }

  .btn-show-details {
    margin-top: 1.4rem;
  }

  .btn-add-new {
    margin-top: 0.8rem;
  }

  .edit-button {
    color: $white;
  }

}

.hero-confirmation {
  background-image: none !important;
  margin-bottom: 2rem;
}

.slant-down::after {
  border-left-width: 0 !important;
}

.leading-lines .start-lines::before {
  content: "" !important;
}

.coupon-code-field {
  margin-top: 0.3rem;
}

.checkout-title-area {
  padding-left: 5%;
}

// Mercado Pago - Custom Styles

.mp__payment-type-title {
  margin-bottom: rem-calc(10px) !important;
}

.mp__card-details {
  img {
    max-height: rem-calc(25px);
    width: auto;
  }
}

.confirm-details .shipping-method {
  text-overflow: unset;
  white-space: wrap;
}
